

.notification {
    margin: 0;
}



.notification-box {
    display: flex;
}

.notification-drop {
    margin: 0 5px;
    padding: 0 5px;
    border: none;
    flex-shrink: 0;
    flex-grow: 0;
    min-width: initial;
    width: 10%;
}

@media (min-width:768px){
    .notification-drop  {
        width: initial;
    }
}

.notification-content {
    max-height: 0;
    transition: max-height .5s;
    width: 100%;
    overflow: hidden;
    background: rgba(0,0,0,.7)
}

.notification-content input {
    width: 99%;
    background-color: #333;
    color: white;
    border: none;
    padding: 5px;
}

.notification-error {
    color: orangered;
    font-size: large;
    padding: 3px;
}

.is-expanded {
    max-height: 500px;
    overflow: visible;
    border-top: 0;
    border: #333 solid 1px;
    padding: 5px;
    margin-top: 16px;
}